<template>
    <!-- Page: pages/my-notifications -->
    <br/>

    <breadcrumbs :items="path"/>
    <a href="/my-notifications" ><span> &lt;  Go to Notifications</span></a>
    <br/>

    <div class="container dfw">
        <h1> Notification Settings</h1>
    </div>

    <div class="general-container">
            <div class="block">
            <h5>Digest Notifications </h5>
            <div class="field">

                <input
                    class="switch is-rounded"
                    type="checkbox"/>
                <label class="agency-filter-label">Receive notifications combined in one diagest</label>
            </div>

    <div>
        <h6>Frequency </h6>
        <custom-select
            :uniqueId="uniqueId"
            :items="frequency"
            name="frequency"
            v-model:value="frequency"
            style="width: 300px"
        ></custom-select>
    </div>
</div>



        <div class="block">
            <h5>Immediate Notifications </h5>
            <div class="field">
                <input
                    class="switch is-rounded"
                    type="checkbox">
                <label class="agency-filter-label">Turn all immediate notifications</label>
            </div>

            <div class="notification-group">
                <div class="content-block__content">
                    <ul class="box-settings">
                        <li v-for="contentType in ContentTypes">
                            <div class="field">
                                <input
                                    class="switch is-rounded"
                                    type="checkbox">
                                <label :for="'contentType-' + contentType.type"
                                       class="agency-filter-label">{{ contentType.title }}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="block">
            <h5>My item  Notifications </h5>

            <section>
                <div class="content-block__content">
                    <div class="bookmarks-filter">
                        Filter by
                        <span @click.prevent="filterByType('contact')" :class="{'active': typeFilter === 'contact'}"
                              title="Filter by Contact"
                        >
                        <tag-contact :tag-text="'Contact'"></tag-contact>
                    </span>
                        <span @click.prevent="filterByType('position')" :class="{'active': typeFilter === 'position'}"
                              title="Filter by Position"
                        >
                        <tag-role :tag-text="'Position'"></tag-role>
                    </span>
                        <span @click.prevent="filterByType('agency')" :class="{'active': typeFilter === 'agency'}"
                              title="Filter by Agency"
                        >
                        <tag-agency :tag-text="'Agency'"></tag-agency>
                    </span>
                        <span @click.prevent="filterByType('budget')" :class="{'active': typeFilter === 'budget'}"
                              title="Filter by Program"
                        >
                        <tag-budget :tag-text="'Program'"></tag-budget>
                    </span>
                        <span @click.prevent="filterByType('vendor')" :class="{'active': typeFilter === 'vendor'}"
                              title="Filter by Vendor"
                        >
                        <tag-vendor :tag-text="'Vendor'"></tag-vendor>
                    </span>
                        <span @click.prevent="filterByType('contract')" :class="{'active': typeFilter === 'contract'}"
                              title="Filter by Contract"
                        >
                        <tag-contract :tag-text="'Contract'"></tag-contract>
                    </span>
                        <span @click.prevent="filterByType('procurement')" :class="{'active': typeFilter === 'procurement'}"
                              title="Filter by Procurement"
                        >
                        <tag-procurement :tag-text="'Procurement'"></tag-procurement>
                    </span>
                    </div>
                </div>
            </section>

            <div class="filter-input field">
                <label :for="'tbl-keyword-' + uniqueId" class="label display-label">Filter by Keyword</label>
                <div class="search-block">
                    <div class="field has-addons">
                        <div class="control">
                            <input type="search" :id="'tbl-keyword-' + uniqueId" v-model="keyword" class="input-search"
                                   placeholder="Filter by keyword"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import UserTools from "./user-tools.vue";
import Breadcrumbs from "./breadcrumbs.vue";
import GeneralContainer from "./general-container.vue";
import TagVendor from "./tag--vendor.vue";
import TagContract from "./tag--contract.vue";
import TagRole from "./tag--role.vue";
import TagProcurement from "./tag--procurement.vue";
import TagBudget from "./tag--budget.vue";
import TagContact from "./tag--contact.vue";
import TagAgency from "./tag--agency.vue";
import Preloader from "./preloader.vue";
import {nextTick, ref} from "vue";
import AppButton from "./app-button.vue";
import Datepicker from "vue3-datepicker";
import {faCalendarDay} from "@fortawesome/pro-duotone-svg-icons";
import CustomSelect from "./custom-select.vue";
import { v4 as uuidv4 } from "uuid";

export default {
    methods: {
        faCalendarDay() {
            return faCalendarDay
        }
    },
    components: {
        CustomSelect,
        Datepicker,
        AppButton,
        Preloader,
        TagAgency, TagContact, TagBudget, TagProcurement, TagRole, TagContract, TagVendor,
        GeneralContainer,
        Breadcrumbs,
        UserTools,
    },

    setup() {
        const notificationsLoading = ref (false)
        const showActive = ref(0);
        const frequency = [
            {name:'once a month'},
            {name:'once a week'},
            {name:'once a day'},
        ]
        const ContentTypes = [
            { title: 'Agencies', },
            { title: 'Budget Entities'},
            { title: 'Contacts' },
            { title: 'Contracts'},
            { title: 'Positions'},
            { title: 'Procurements'},
            { title: 'Vendors'},
        ];
        const keyword = ref('');
        const checkedOption = ref([])
        const clickActive = (event) => {
            showActive.value = event.target.checked ? 1 : 0;

            nextTick(() => {
                loadProcurements(true);
            });
        }

        // Generate a unique ID for each instance
        const uniqueId = computed(() => {
            return uuidv4();
        });

        return {
            notificationsLoading,
            frequency,
            path: [{ title: 'Notification settings' }],
            checkedOption,
            showActive,
            clickActive,
            ContentTypes,
            keyword,
            uniqueId,
        }
    }
}

</script>

<style lang="scss" scoped>

@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mixins/_bemify.scss";

.input-search {
    border: 2px solid #022c43;
    height: 50px;
    width: 300px;
}

.box-settings {
    display: flex;
    flex-wrap: wrap;
}
.box-settings>* {
    flex: 1 1 440px;
}
.field {
    width: 33%;
}
.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    background-color: white;
    padding: 15px;
}
.row {
    display: flex;
    flex-direction: row;
}
.filter-left {
    border: 1px solid $blue--med;
    width: 30%;
    background-color: white;
}
.filter-body {
    display: flex;
    flex-direction: column;
    width: 70%;
}
.active-only {
    background-color: rgba(51, 116, 170, 0.3);
    text-align: center;
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.general-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
